import React, { useEffect, useContext } from 'react'
import { NextSeo } from 'next-seo'
import { ErrorStatusContext } from '~/components/atoms/ErrorStatusProvider'
import LogoHeader from '~/components/molecules/LogoHeader'
import ErrorMessage from '~/components/molecules/ErrorMessage'
import styled from 'styled-components'

export default function ErrorPage({ statusCode }: { statusCode: number }) {
  const { dispatch } = useContext(ErrorStatusContext)

  useEffect(() => {
    dispatch({ type: 'set', payload: { status: statusCode } })

    return () => {
      dispatch({ type: 'reset' })
    }
  }, [statusCode])

  return (
    <>
      <NextSeo noindex />
      <LogoHeader />
      <ErrorContainer>
        <ErrorMessage statusCode={statusCode} />
      </ErrorContainer>
    </>
  )
}

const ErrorContainer = styled.div`
  text-align: center;
  margin-top: 128px;
`
