import styled from 'styled-components'
import { CloudOff, Error } from '@material-ui/icons'

interface Props {
  statusCode?: number
}

export default function ErrorMessage({ statusCode }: Props) {
  return (
    <Container>
      {statusCode === 404 ? (
        <>
          <CloudOff style={{ fontSize: 48 }} />
          <Message>データがありません</Message>
        </>
      ) : (
        <>
          <Error style={{ fontSize: 48 }} />
          <Message>エラーが発生しました</Message>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  text-align: center;
  margin-top: 128px;
  color: ${({ theme }) => theme.colors.OnSurface5};
`
const Message = styled.div`
  ${({ theme }) => theme.fonts.Bold16};
  margin-top: 8px;
`
